import React from "react";
import {
  List, Datagrid, TextField, DateField, SingleFieldList, ChipField, ArrayField,
} from "react-admin";
import { makeStyles } from "@material-ui/core";

import ReviewDetails from "./ReviewDetails";

const useStyles = makeStyles({
  table: {
    borderRadius: 10,
    overflow: "scroll",
  }
});

function ReviewsList(props) {
  const classes = useStyles();
  return (
    <List {...props} bulkActionButtons={false} sort={{ field: "createdAt", order: "DESC" }}>
      <Datagrid expand={<ReviewDetails />} classes={{ table: classes.table }}>
        <TextField source="User.username" label="Username" />
        <DateField source="createdAt" />
        <TextField source="Book.title" label="Book title" />
        <ArrayField source="Book.Authors" label="Authors" sortable={false}>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="Book.Genres" label="Genres" sortable={false}>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
      </Datagrid>
    </List>
  );
}

export default ReviewsList;
