import React from 'react';
import Chip from '@material-ui/core/Chip';

function ChipList({ record, source }) {
  return (
    <div>
      {record[source]?.map((item, index) => (
          <Chip key={item + index} label={item} />
        ))}
    </div>
  );
}

export default ChipList;
