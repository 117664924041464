import React from "react";
import {
  List, Datagrid, TextField, DateField, ArrayField, SingleFieldList, ChipField,
} from "react-admin";
import { makeStyles } from "@material-ui/core";

import BooksDetails from "./BooksDetails";

const useStyles = makeStyles({
  table: {
    borderRadius: 10,
    overflow: "scroll",
  }
});

function BooksList(props) {
  const classes = useStyles();
  return (
    <List {...props} bulkActionButtons={false} title="List of books" sort={{field:"publish_date", order:"DESC"}}>
      <Datagrid expand={<BooksDetails />} classes={{ table: classes.table }}>
        <TextField source="title" />
        <ArrayField source="Authors" sortable={false}>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <TextField source="pages" />
        <TextField source="reviews" sortable={false} />
        <ArrayField source="Tags" sortable={false}>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <TextField source="isbn" />
        <DateField source="publish_date" />
      </Datagrid>
    </List>
  );
}

export default BooksList;
