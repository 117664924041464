import React from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Toolbar, Typography,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import classnames from "classnames"

import useStyles from "./materialStyles";

// eslint-disable-next-line no-unused-vars
const recommended = ["Adults", "Teen-agers", "Kids", "Women", "Men"];
export default ({ id, record }) => {
  const classes = useStyles();

  const columnsCataloguing = [
    { field: "Time", id: "time" },
    { field: "Places", id: "places" },
    { field: "Tags", id: "tags" },
    { field: "True Events", id: "trueEvents" },
    { field: "Real Characters", id: "realCharacters" },
    { field: "Author's Nationality", id: "authorsNationality" },
  ];

  const d = new Date(record.createdAt);
  const formattedDate = `${d.getDate()}-${
    d.getMonth() + 1
  }-${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;

  const rowsCataloguing = [
    { field: "time", id: "time", value: formattedDate },
    { field: "places", id: "places", value: record.Book?.places?.join(", ") },
    {
      field: "tags",
      id: "tags",
      value: record.Tags?.map(({ name }) => name)
                   ?.join(", "),
    },
    {
      field: "trueEvents",
      id: "trueEvents",
      value: record.TrueCharacters?.map(({ name }) => name)
                   ?.join(", "),
    },
    {
      field: "realCharacters",
      id: "realCharacters",
      value: record.RealCharacters?.map(({ name }) => name)
                   ?.join(", "),
    },
    { field: "authorsNationality", id: "authorsNationality", value: record.authorsNationality },
  ];

  const columnsReviews = [
    { field: "Originality", id: "originality" },
    { field: "Plot", id: "plot" },
    { field: "Writing Style", id: "writingStyle" },
    { field: "General", id: "general" },
    { field: "Comment", id: "comment" },
  ];

  const rowsReviews = [
    { field: "originality", id: "originality", value: record.rateOriginality },
    { field: "plot", id: "plot", value: record.ratePlot },
    { field: "writingStyle", id: "writingStyle", value: record.rateWritingStyle },
    { field: "general", id: "general", value: record.rateOverall },
    { field: "comment", id: "comment", value: record.comment },
  ];

  const columnsRecommendedFor = [
    { field: "All", id: "all" },
    { field: "Woman", id: "woman" },
    { field: "Men", id: "men" },
    { field: "Adults", id: "adults" },
    { field: "Teenagers", id: "teenagers" },
    { field: "Kids", id: "kids" },
  ];

  const rowsRecommendedFor = [
    { field: "all", value: record.Recommendeds.length >= 5 ? <DoneIcon /> : <ClearIcon />, id: "all" },
    {
      field: "woman",
      value: record.Recommendeds.find(({ name }) => name === "Women") ? <DoneIcon /> : <ClearIcon />,
      id: "woman"
    },
    {
      field: "men",
      value: record.Recommendeds.find(({ name }) => name === "Men") ? <DoneIcon /> : <ClearIcon />,
      id: "men"
    },
    {
      field: "adults",
      value: record.Recommendeds.find(({ name }) => name === "Adults") ? <DoneIcon /> : <ClearIcon />,
      id: "isTrueEvents"
    },
    {
      field: "teenagers",
      value: record.Recommendeds.find(({ name }) => name === "Teen-agers") ? <DoneIcon /> : <ClearIcon />,
      id: "teenagers"
    },
    {
      field: "kids",
      value: record.Recommendeds.find(({ name }) => name === "Kids") ? <DoneIcon /> : <ClearIcon />,
      id: "kids"
    },
  ];

  return (
    <>
      <TableContainer className={classes.nestedTable} component={Paper}>
        <Toolbar>
          <Typography variant="h6" id="tableTitle" component="div">
            CATALOGUING INFO
          </Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              {columnsCataloguing.map(({ field, id: columnId }) => (
                <TableCell className={classnames(classes.tableCell, classes.headCell)} key={columnId}>{field}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={id}>
              {rowsCataloguing.map(({ value, id: rowId }) => (
                <TableCell className={classes.tableCell} padding="none" key={rowId}>
                  {value}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>

      </TableContainer>
      <TableContainer className={classes.nestedTable} component={Paper}>
        <Toolbar>
          <Typography variant="h6" id="tableTitle" component="div">
            REVIEW
          </Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              {columnsReviews.map(({ field, id: columnId }) => (
                <TableCell className={classnames(classes.tableCell, classes.headCell)} key={columnId}>{field}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={id}>
              {rowsReviews.map(({ value, id: rowId }) => (
                <TableCell className={classes.tableCell} padding="none" key={rowId}>
                  {value}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>

      </TableContainer>
      <TableContainer className={classes.nestedTable} component={Paper}>
        <Toolbar>
          <Typography variant="h6" id="tableTitle" component="div">
            RECOMMENDED FOR
          </Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              {columnsRecommendedFor.map(({ field, id: columnId }) => (
                <TableCell className={classnames(classes.tableCell, classes.headCell)} key={columnId}>{field}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={id}>
              {rowsRecommendedFor.map(({ value, id: rowId }) => (
                <TableCell className={classes.tableCell} padding="none" key={rowId}>
                  {value}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
