import React from 'react';
import { Admin, Resource } from 'react-admin';

import dataProvider from './api/dataProvider';
import UserList from './views/Users';
import BooksList from './views/Books';
import AdvanceSearch from './views/AdvanceSearch';
import ReportsList from './views/Reports';
import ReviewsList from './views/Reviews';
import authProvider from './api/authProvider';

const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider}>
    <Resource name="users" list={UserList} />
    <Resource name="books" list={BooksList} />
    <Resource name="advance-searches" list={AdvanceSearch} />
    <Resource name="reviews" list={ReviewsList} />
    <Resource name="reports" list={ReportsList} />
  </Admin>
);

export default App;
