import React from "react";
import { FilterList, FilterListItem } from "react-admin";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { Card as MuiCard, CardContent, withStyles } from "@material-ui/core";
import {
  endOfMonth,
  endOfWeek,
  startOfToday,
  endOfToday,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks
} from "date-fns";

const LastVisitedFilter = () => (
  <FilterList label="Filter by period" icon={<AccessTimeIcon />}>
    <FilterListItem
      label="Today"
      value={{
        last_seen_gte: startOfToday()
          .toISOString(),
        last_seen_lte: endOfToday()
          .toISOString(),
      }}
    />
    <FilterListItem
      label="This week"
      value={{
        last_seen_gte: startOfWeek(new Date())
          .toISOString(),
        last_seen_lte: endOfWeek(new Date())
          .toISOString(),
      }}
    />
    <FilterListItem
      label="Last week"
      value={{
        last_seen_gte: subWeeks(startOfWeek(new Date()), 1)
          .toISOString(),
        last_seen_lte: startOfWeek(new Date())
          .toISOString(),
      }}
    />
    <FilterListItem
      label="This month"
      value={{
        last_seen_gte: startOfMonth(new Date())
          .toISOString(),
        last_seen_lte: endOfMonth(new Date())
          .toISOString(),
      }}
    />
    <FilterListItem
      label="Last month"
      value={{
        last_seen_gte: subMonths(startOfMonth(new Date()), 1)
          .toISOString(),
        last_seen_lte: startOfMonth(new Date())
          .toISOString(),
      }}
    />
  </FilterList>
);


const Card = withStyles(theme => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1, // display on the left rather than on the right of the list
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

const FilterSidebar = () => (
  <Card>
    <CardContent>
      <LastVisitedFilter />
    </CardContent>
  </Card>
);

export default FilterSidebar;
