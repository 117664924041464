import {fetchUtils} from "react-admin";
import restProvider from "ra-data-simple-rest";
import axios from "axios";
import constants from '../constants';

const BASE_URL = `${constants.API_URL}/api/admin`;
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        // eslint-disable-next-line no-param-reassign
        options.headers = new Headers({Accept: "application/json"});
    }
    const token = localStorage.getItem("accessToken");
    // add your own headers here
    options.headers.set("authorization", token);
    return fetchUtils.fetchJson(url, options)
        .then((res) => {
            debugger
            return {
                ...res,
                json: res.json.data,
            };
        });
};

const dataProvider = restProvider(BASE_URL, httpClient);
export default {
    ...dataProvider,
    async getList(resource, params) {
        let URL = `${BASE_URL}/${resource}`;
        debugger
        if (resource === "reports") {
            URL = params.filter?.last_seen_gte ? `${BASE_URL}/${resource}?startDate=${params.filter.last_seen_gte}&endDate${params.filter.last_seen_lte}` : `${BASE_URL}/${resource}`;
            debugger
        }
        try {
            const token = localStorage.getItem("accessToken");
            const res = await axios(URL, {
                params: {
                    page: params.pagination.page,
                    perPage: params.pagination.perPage,
                    ...(params.sort && {field: params.sort.field, order: params.sort.order})
                },
                headers: {
                    authorization: token,
                },
            });
            if (res.config.url.includes("/admin/reports")) {
                const report = {...res.data.data[0]};
                report.id = new Date().toISOString();
                return {
                    data: [{...report}],
                    total: res.data.total,
                };
            }
            return {
                data: res.data.data,
                total: res.data.total,
            };
        } catch (e) {
            Promise.reject(e);
        }
        return false;
    },
};
