import React from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
} from '@material-ui/core';

import useStyles from './materialStyles';

export default ({ id, record }) => {
  const classes = useStyles();

  const columns = [
    { field: 'Comment', id: 'comment' },
    { field: 'Publisher', id: 'publisher' },
    { field: 'Character names', id: 'characterNames' },
    { field: 'Has True Events', id: 'isTrueEvents' },
    { field: 'Has Real Characters', id: 'isRealCharacters' },
    { field: 'Image', id: 'image' },
  ];

  const rows = [
    { field: 'comment', value: record.comment, id: 'comment' },
    { field: 'publisher', value: record.publisher, id: 'publisher' },
    { field: 'characterNames', value: record.CharacterNames.map(({ name }) => name), id: 'characterNames' },
    { field: 'isTrueEvents', value: JSON.stringify(record.isTrueEvents), id: 'isTrueEvents' },
    { field: 'isRealCharacters', value: JSON.stringify(record.isRealCharacters), id: 'isRealCharacters' },
    { field: 'image', value: record.image, id: 'image' },
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(({ field, id: columnId }) => (
              <TableCell key={columnId}>{field}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={id}>
            {rows.map(({ value, id: rowId, field }) => {
              if (field === 'image') {
                return (
                  <TableCell className={classes.expandedCell} padding="none" key={rowId}>
                    <img src={value} alt="book" />
                  </TableCell>
                );
              }
              return (
                <TableCell className={classes.expandedCell} padding="none" key={rowId}>
                  {value}
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

  );
};
