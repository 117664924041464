import React from "react";
import { List, Datagrid, TextField, NumberField } from "react-admin";
import { makeStyles } from "@material-ui/core";

import  FilterSidebar  from "./components/Filters";

const useStyles = makeStyles({
  table: {
    borderRadius: 10,
    overflow: "scroll",
  }
});

function ReportsList(props) {
  const classes = useStyles();
  return (
    <List {...props} bulkActionButtons={false} aside={<FilterSidebar />} pagination={false}>
      <Datagrid classes={{ table: classes.table }}>
        <NumberField source="addedBooksByPeriod" sortable={false}/>
        <TextField source="addedReviewsByPeriod" sortable={false} />
        <TextField source="totalBooksOnStartPeriod" sortable={false} />
        <TextField source="registeredUserByPeriod" sortable={false} />
        <TextField source="totalReviewsOnStartPeriod" sortable={false} />
        <TextField source="totalUsersOnStartPeriod" sortable={false} />
        <TextField source="totalBooks" sortable={false} />
        <TextField source="totalReviews" sortable={false} />
        <TextField source="totalUsers" sortable={false} />
      </Datagrid>
    </List>
  );
}

export default ReportsList;
