import React from 'react';
import {
  List, Datagrid, TextField, DateField,
} from 'react-admin';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    borderRadius: 10,
    overflow: "scroll",
  }
});

function UsersList(props) {
  const classes = useStyles();
  return (
    <List {...props} bulkActionButtons={false} sort={{ field: 'createdAt', order: 'DESC' }}>
      <Datagrid classes={{ table: classes.table }}>
        <TextField source="username" />
        <TextField source="email" />
        <TextField source="gender" />
        <TextField source="role"  />
        <TextField source="nationality" />
        <TextField source="reviews" sortable={false} />
        <TextField source="books" sortable={false} label="Books added to added"/>
        <DateField source="date_of_birth" />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
}

export default UsersList;
