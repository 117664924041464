import { makeStyles } from '@material-ui/core';

export default makeStyles({
  nestedTable: {
    opacity: '.8',
    background: 'rgba(233, 233, 233, .6)',
    marginBottom: 18,
  },
  expandedCell: {
    padding: '0 16px !important',
  },
  headCell:{
    fontWeight: "bold"
  },
  tableCell:{
    padding:"16px !important",
    border: "1px solid gray"
  }
});
