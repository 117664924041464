import React from "react";
import {
  List, Datagrid, SingleFieldList, ChipField, BooleanField, DateField, ArrayField,
} from "react-admin";
import { makeStyles } from "@material-ui/core";

import ChipList from "../../components/ChipList";

const useStyles = makeStyles({
  table: {
    borderRadius: 10,
    overflow: "scroll",
  }
});

function AdvanceSearchList(props) {
  const classes = useStyles();
  return (
    <List {...props} bulkActionButtons={false} sort={{ field: "createdAt", order: "DESC" }}>
      <Datagrid classes={{ table: classes.table }}>
        <ArrayField source="Genres">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <DateField source="createdAt" />
        <ChipList source="places" />
        <ChipList source="tags" />
        <BooleanField source="isRealCharacters" sortable={false} />
        <BooleanField source="isTrueEvents" sortable={false} />
        <ChipList source="characterNames" />
      </Datagrid>
    </List>
  );
}

export default AdvanceSearchList;
