import axios from "axios";
import constants from '../constants';

const auth = {
  // called when the user attempts to log in
  async login({ username, password }) {
    const res = await axios.post(`${constants.API_URL}/api/login`, {
      email: username, password,
    });
    localStorage.setItem("accessToken", res?.data?.accessToken);
    localStorage.setItem("refreshToken", res?.data?.refreshToken);
    localStorage.setItem("userId", res?.data?.userId);
    return Promise.resolve();
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userId");
    return Promise.resolve();
  },
  // called when the API returns an error
  async checkError({ status, method, url, data }) {
    if (status === 401 || status === 403) {
      const refreshToken = localStorage.getItem("refreshToken");
      const userId = localStorage.getItem("userId");
      try {
        const res = await axios.post(`${constants.API_URL}/api/auth-token-refresh`, {
          userId,
          refreshToken,
        });
        localStorage.setItem("accessToken", res?.data?.accessToken);
        localStorage.setItem("refreshToken", res?.data?.refreshToken);
        localStorage.setItem("userId", res?.data?.userId);
        return axios({ method, url, data });
      }
      catch (error) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("userId");
        return Promise.reject();
      }
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => (localStorage.getItem("accessToken")
    ? Promise.resolve()
    : Promise.reject()),
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};

export default auth;
